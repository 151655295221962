import { H } from "highlight.run";
import { dev } from "$app/environment";

H.init("7e32ol6d", {
  environment: dev ? "dev" : "prod",
  manualStart: true,
  version: "dashboard",
  tracingOrigins: ["localhost", "127.0.0.1", "divine-sunset-8066.fly.dev"],
  enableStrictPrivacy: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
  integrations: {
    mixpanel: {
      projectToken: "450de6bda19977355064f7fafd07caa9",
    },
  },
});